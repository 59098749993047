.testimonials {
  @include content();

  &--title {
    @include font-size(header-font-size(2));
    margin-bottom: $v;
    text-align: center;
    color: $dark-blue;
  }
}

.testimonial {
  $photo-size: 6 * $v;

  // Add vertical spacing to each testimonial.
  margin: 2 * $v 0;

  // When the screen is small center the author, position and photo. We need to
  // center the image via margin instead of text-align as it's a block element.
  @include when-small-or-less {
    &--author, &--position {
      text-align: center;
    }

    &--photo {
      margin: $v auto 0 auto;
    }
  }

  // When the screen is larger then we show the photo to the left of the text.
  // We do that by flipping the markup order of text + photo using the order
  // attribute.
  @include above-small {
    display: flex;

    &--content {
      // Prevent the text from crashing into the photo.
      margin-left: 2 * $h;

      // Render the text to the right of the photo on larger screens.
      order: 1;
    }

    &--photo {
      // Render the photo to the left of the text on larger screens.
      order: 0;
    }
  }

  &--text {
    @include justify();
    margin-bottom: $v;
  }

  &--photo {
    @include circle($photo-size);
    display: block;
  }

  &--author {
    color: $dark-blue;
  }

  &--position {
    color: $grayish-blue;
  }
}
