.hero {
  background-color: $dark-blue;

  &--content {
    @include content();

    // Split the hero between the text and the photo.
    display: flex;

    // Align items vertically.
    align-items: center;
  }

  &--copy {
    flex: 1;

    // Prevent text from crashing into the photo if its shown.
    @include above-small {
      padding-right: $h;
    }
  }

  &--photo {
    flex: 1;

    // Required to make the photo scale up and down with the browser window.
    width: 100%;

    // Hide the photo on smaller screens.
    @include when-small-or-less {
      display: none;
    }
  }

  &--title {
    @include font-size(header-font-size(2));
    color: $white;
  }

  &--tagline {
    @include font-size(24px);
    margin-bottom: 2rem * $line-height;

    color: $grayish-blue;
  }
}
