.contact {
  &--header {
    display: grid;
    column-gap: $h;

    @include when-small-or-less {
      grid-template-columns: 1fr;
    }

    @include above-small {
      grid-template-columns: 1fr 1fr;
    }
  }

  &--control {
    margin-bottom: $v;
  }

  &--label {
    display: block;
    color: $grayish-blue;
    width: 100%;
  }

  &--input, &--textarea {
    display: block;
    width: 100%;
    border: solid 1px $light-blue2;
    line-height: $effective-line-height;
    padding: 0 calc($h / 2);
    box-sizing: border-box;
    border-radius: 3px;
    resize: none;
  }

  &--input__hidden {
    display: none;
  }

  &--textarea {
    margin-bottom: $v;
    font-family: inherit;
  }
}