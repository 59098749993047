.footer {
  background-color: $dark-blue;

  @include above-small {
    // Show the arrow image only on larger screens.
    &--content {
      background: no-repeat right url("/assets/images/shared/footer@1.png");

      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background: no-repeat right url("/assets/images/shared/footer@2.png");
      }
    }

    &--subtitle {
      // Prevent the CTA from crashing into the arrow image.
      padding-right: 411px;;
    }
  }

  &--content {
    @include content();
  }

  &--title {
    @include font-size(header-font-size(2));
    color: $white;
  }

  &--subtitle {
    color: $grayish-blue;
  }

  a {
    color: #e0e0e0;
  }
}
