// GLOBAL SETTINGS

// Maximum content container width.
$container-max-width: 960px;

// Horizontal content container padding.
$container-padding: $h;

// Screen width thresholds.
$tiny-threshold: 600px;
$small-threshold: 800px;

// COMPONENTS

// A component containing the actual content in a piece of markup.
@mixin content($padding-top: $v, $padding-bottom: $v) {
  max-width: $container-max-width;
  padding: $padding-top $container-padding $padding-bottom $container-padding;
  margin: 0 auto;
}

// RESPONSIVENES

@mixin when-tiny() {
  @media screen and (max-width: $tiny-threshold) {
    @content;
  }
}

@mixin when-above-tiny() {
  @media screen and (min-width: $tiny-threshold) {
    @content;
  }
}

@mixin when-small() {
  @media screen and (min-width: $tiny-threshold) and (max-width: $small-threshold) {
    @content;
  }
}

// Rules that should apply only to small screens.
@mixin when-small-or-less() {
  @media screen and (max-width: $small-threshold) {
    @content;
  }
}

// Rules that should apply only to larger screens.
@mixin above-small {
  @media screen and (min-width: $small-threshold) {
    @content;
  }
}

// LAYOUT

// Always show the vertical scroll bar so that the width of the site doesn't
// change after navigating to a page with less content.
html {
  overflow-y: scroll;
}
