.intro {
  background-color: $bluish-gray;

  &--content {
    @include content($v, $v);
    color: $grayish-blue;

    p {
      @include font-size(22px);
      margin: 0;
    }
  }

  &--header {
    color: $dark-blue;
  }
}

.main {
  &--content {
    @include content(0, 0);

    padding-top: $v;
    padding-bottom: $v;
  }
}
