.services {
  background-color: $bluish-gray;

  &--content {
    @include content();
  }

  &--title {
    @include font-size(header-font-size(2));

    margin-bottom: $v;

    color: $dark-blue;
    text-align: center;
  }

  &--list {
    display: grid;

    // At most 3 columns all equal in width.
    grid-template-columns: repeat(auto-fill, minmax(calc($container-max-width / 4), 1fr));

    // Rows should be as high as the highest cell.
    grid-auto-rows: max-content;

    // Add whitespace between cells for extra readability.
    row-gap: $v;
    column-gap: $h;
  }
}

.service {
  padding-top: $v;
  padding-bottom: $v;

  // Center the icon and the service name.
  text-align: center;

  &--name {
    @include font-size(24px);
    color: $dark-blue;
  }

  &--summary {
    @include justify();
    margin: $v 0 0 0;
    color: $grayish-blue;
  }
}

.service-details {
  text-align: center;
  margin: 2 * $v 0;
}
