// Button-like element.
@mixin button($color, $background-color) {
  display: inline-block; // Needed to set the height explicitly.
  height: 2 * $v;

  line-height: 2 * $v; // Center the label vertically.
  padding: 0 2 * $h; // Add horizontal padding.
  text-decoration: none; // Don't underline the text like with regular links.

  color: $color;
  background-color: $background-color;

  border-radius: 5px;
}

// Circular image.
@mixin circle($radius) {
  border-radius: 50%;
  width: $radius;
  height: $radius;
}

// Button-like link.
.button {
  @include button($white, $purple1);
}

// Hyperlink resembling regular text.
.unstyled-link, .unstyled-link:hover {
  color: inherit;
  text-decoration: inherit;
}

// Horizontal separator
.separator {
  width: 80%;
  height: 1px;
  border: none;
  background-color: $light-blue;
  margin: 0 auto;
}

// E-mail subscription box
.subscription {
  border: solid 1px $light-blue;
  background-color: $bluish-gray;

  border-radius: 3px;

  margin: 2 * $v 0 $v 0;
  padding: calc($v / 2) $h;

  display: flex;
  flex-direction: row;

  .formkit-form {
    margin: 0 auto;
  }

  &--note {
    flex-grow: 1;
  }

  &--header {
    font-weight: bold;
  }

  &--details {
    margin-top: 0;
  }

  &--cta {
    @include font-size(20px);

    color: $dark-blue;
    font-weight: bold;
  }
}

.twitter-follow-button {
  flex-grow: 0;
  flex-shrink: 0;
  align-self: center;
}

// Figures

// div.highlight and figure.highlight is emitted by Jekyll and doesn't contain a
// content wrapper we could apply padding to.
div.highlight, figure.highlight, .figure__content {
  padding: calc($v / 2) $h;
  overflow-x: auto;
}

.figure {
  code {
    background: inherit;
    color: inherit;
  }

  table {
    width: 100%;
  }

  tr:nth-child(even) {
    background-color: darken($bluish-gray, 3%);
  }

  thead {
    background-color: $grayish-blue;
    color: $bluish-gray;
  }

  tfoot td {
    border-top: solid 1px $grayish-blue;
    font-weight: bold;
  }

  th, td {
    @include font-size($table-font-size);
    padding: 0 calc($h / 2);
  }

  .placeholder {
    text-align: center;
    font-style: italic;
  }

  .item-level-1 {
    padding-left: $h;
  }

  .item-level-2 {
    padding-left: 2 * $h;
  }

  .item-level-3 {
    padding-left: 3 * $h;
  }

  .item-level-4 {
    padding-left: 4 * $h;
  }
}

figcaption {
  background-color: $grayish-blue;
  color: $bluish-gray;

  padding: calc($v / 2) $h;
}

// Tables
table {
  .numeric {
    text-align: right;
  }
}

// Callout
.callout {
  margin: $v 0;
  padding: calc($v / 2) $h;
  border-radius: 5px;
  background-color: color-mix(in srgb, currentcolor, white 93%);

  h4, h5 {
    margin-top: 0;
    font-weight: bold;
    font-size: inherit;
  }

  &-tip {
    color: #0dc419;
  }

  &-info {
    color: #3575e0;
  }

  &-danger {
    color: #d00;
  }

  &-warning {
    color: #b2ac00;
  }
}
