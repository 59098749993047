// TYPOGRAPHY

// GLOBAL SETTINGS

$font-size: 18px;
$effective-line-height: 28px;
$line-height: calc($effective-line-height / $font-size);

// Header font sizes starting with H2.
$header-font-sizes: 32px, 26px, 22px, 20px;

// Table font size.
$table-font-size: 16px;

$h: 1rem;
$v: $line-height * 1rem;

// TEXT LAYOUT

// Justify and hyphenate the content. Use this mixin instead of just setting
// text-align in order to achieve better typographic outcomes.
@mixin justify {
  text-align: justify;
  hyphens: auto;
}

// FONT SIZE

// Change font-size and line-height to ensure it's always a multiple of $effective-line-height.
@mixin font-size($size) {
  font-size: #{calc($size / $font-size)}rem;
  line-height: ceil(calc($size / $effective-line-height)) * calc($effective-line-height / $size);
}

// Return font-size for a given header level.
@function header-font-size($level) {
  @if $level == 1 {
    @error "H1 is used only for the logo. Trying to set its size is probably a bug."
  }

  @return nth($header-font-sizes, $level - 1);
}

// RESET

html {
  font-family: San Francisco, Arial, sans-serif;
}

html, body, p, h1, h2, h3, h4, h5, h6, blockquote, input, textarea {
  font-weight: normal;
  font-size: $font-size;
  line-height: $line-height;
}

// CONTENT

// We break with BOM here as it's simpler to constraint the rules below to the
// actual content which always should be wrapped in MAIN.
main {
  // Hide horizontal overflow.
  overflow-x: hidden;

  p {
    @include justify();
  }

  p + p {
    margin-top: $v;
  }

  li {
    @include justify();
    margin-left: 2 * $h;
  }

  h2 {
    @include font-size(header-font-size(2));
  }

  h3 {
    @include font-size(header-font-size(3));
    margin: calc(3 * $v / 2) 0 calc($v / 2) 0;
    font-weight: bold;
  }

  h4 {
    @include font-size(header-font-size(4));
    margin-top: calc(3 * $v / 4);
    margin-bottom: calc($v / 4);
  }

  ul, ol {
    margin-top: $v;
    margin-bottom: $v;
  }

  ul {
    li {
      list-style-type: "- ";
    }
  }

  dt {
    font-weight: bold;
  }

  dd {
    margin-bottom: $v;
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}
