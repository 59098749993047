.navbar {
  @include content(0, 0);

  $height: 4 * $v;
  height: $height;

  // Use flexbox to make the logo and the links take the opposite sides.
  display: flex;

  // Space the logo and the links out.
  justify-content: space-between;

  // Align the logo and the links vertically.
  align-items: center;

  &--home, &--logo {
    // Rendering as inline results in content overflows due to using an image
    // for the header content.
    display: block;
  }

  &--menu {
    @include button($white, $purple2);

    // It's a BUTTON tag but it should resemble a link so we change the cursor.
    cursor: pointer;

    // Prevent the button from crashing into the logo.
    margin-left: $h;

    // Prevent the text from being selected when the user clicks too fast.
    user-select: none;

    // Show the menu button only on smaller screens.
    @include above-small {
      display: none;
    }
  }

  // Don't show the checkbox we use to determine whether the menu should be
  // expanded or not.
  &--expanded {
    display: none;
  }

  &--items {
    // On small screens, the menu expands over the page content. Without this
    // attribute its background is transparent.
    background-color: $white;

    // Render navigation lins on a single line.
    white-space: nowrap;

    @include when-small-or-less {
      // Render as a rectangle right below the navbar. Notice bottom or height
      // isn't set because we let the browser to determine the actual height.
      display: block;
      position: absolute;
      top: $height;
      left: 0;
      right: 0;

      // Prevent links from overflowing during expansion.
      overflow: hidden;

      // height can't be animated so we use max-height to control expansion.
      max-height: 0px;
      transition: max-height 0.3s cubic-bezier(.19,1,.22,1);
    }
  }

  &--item {
    @include when-small-or-less {
      // Make each link occupy one horizontal line.
      display: block;
      text-align: center;
    }

    @include above-small {
      // Render elements next to each other and make them respect line-height.
      display: inline-block;

      // Add some horizontal separation to the elements.
      margin-left: $h;
    }
  }

  &--link {
    // Use whole line-height.
    display: inline-block;

    color: $grayish-blue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &__active {
      color: $dark-blue;
      text-decoration: underline;
    }

    @include when-small-or-less {
      // Add vertical spacing in the dropdown menu.
      margin: calc($v / 2) 0;
    }
  }

  &--button {
    @include button($white, $purple2);
  }

  @include when-small-or-less {
    &--expanded:checked + &--items {
      // Expand the menu right below the navbar.
      max-height: 10 * $v;

      // Spacing at the bottom of the expanded menu.
      padding-bottom: $v;
    }
  }
}
