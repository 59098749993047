.article-summary {
	margin: 0 0 $v 0;

	h3 {
		margin-bottom: calc($v / 2);
	}

	p {
		margin: calc($v / 2) 0;
	}
}

*+.article-summary {
	margin-top: $v;
}